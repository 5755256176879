var scripts = (function () {
  const init = () => {
    Waves.init();
    Waves.attach(
      ".btn:not([class*='btn-relief-']):not([class*='btn-gradient-']):not([class*='btn-outline-']):not([class*='btn-flat-'])",
      ['waves-float', 'waves-light']
    );
    Waves.attach("[class*='btn-outline-']");
    Waves.attach("[class*='btn-flat-']");

    if (feather) {
      feather.replace({
        width: 14,
        height: 14,
      });
    }
  };

  const auth = () => {
    $('.form-password-toggle .input-group-text').on('click', function (e) {
      e.preventDefault();
      var $this = $(this),
        inputGroupText = $this.closest('.form-password-toggle'),
        formPasswordToggleIcon = $this,
        formPasswordToggleInput = inputGroupText.find('input');

      if (formPasswordToggleInput.attr('type') === 'text') {
        formPasswordToggleInput.attr('type', 'password');
        if (feather) {
          formPasswordToggleIcon
            .find('svg')
            .replaceWith(feather.icons['eye'].toSvg({ class: 'font-small-4' }));
        }
      } else if (formPasswordToggleInput.attr('type') === 'password') {
        formPasswordToggleInput.attr('type', 'text');
        if (feather) {
          formPasswordToggleIcon
            .find('svg')
            .replaceWith(
              feather.icons['eye-off'].toSvg({ class: 'font-small-4' })
            );
        }
      }
    });

    var inputContainer = document.querySelector('.auth-input-wrapper');
    if (inputContainer) {
      inputContainer.onkeyup = function (e) {
        var target = e.srcElement;
        var maxLength = parseInt(target.attributes['maxlength'].value, 10);
        var currentLength = target.value.length;

        if (e.keyCode === 8) {
          if (currentLength === 0) {
            var next = target;
            while ((next = next.previousElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() == 'input') {
                next.focus();
                break;
              }
            }
          }
        } else {
          if (currentLength >= maxLength) {
            var next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() == 'input') {
                next.focus();
                break;
              }
            }
          }
        }
      };
    }

    const numeralMask = document.querySelectorAll('.numeral-mask');
    if (numeralMask.length) {
      numeralMask.forEach((e) => {
        new Cleave(e, {
          numeral: true,
        });
      });
    }
  };

  const main = () => {
    $('.scroll-top').on('click', function () {
      $('html, body').animate({ scrollTop: 0 }, 75);
    });
  };

  const mode = () => {
    var $html = $('html');

    var dataLayout = $html.attr('data-layout')
      ? $html.attr('data-layout')
      : 'light-layout';

    $('.nav-link-style').on('click', function () {
      var currentLayout = getCurrentLayout(),
        switchToLayout = '',
        prevLayout = localStorage.getItem(
          dataLayout + '-prev-skin',
          currentLayout
        );

      // If currentLayout is not dark layout
      if (currentLayout !== 'dark-layout') {
        // Switch to dark
        switchToLayout = 'dark-layout';
      } else {
        // Switch to light
        // switchToLayout = prevLayout ? prevLayout : 'light-layout';
        if (currentLayout === prevLayout) {
          switchToLayout = 'light-layout';
        } else {
          switchToLayout = prevLayout ? prevLayout : 'light-layout';
        }
      }
      // Set Previous skin in local db
      localStorage.setItem(dataLayout + '-prev-skin', currentLayout);
      // Set Current skin in local db
      localStorage.setItem(dataLayout + '-current-skin', switchToLayout);

      // Call set layout
      setLayout(switchToLayout);

      // ToDo: Customizer fix
      $('.horizontal-menu .header-navbar.navbar-fixed').css({
        background: 'inherit',
        'box-shadow': 'inherit',
      });
      $('.horizontal-menu .horizontal-menu-wrapper.header-navbar').css(
        'background',
        'inherit'
      );
    });

    function getCurrentLayout() {
      var currentLayout = '';
      if ($html.hasClass('dark-layout')) {
        currentLayout = 'dark-layout';
      } else if ($html.hasClass('bordered-layout')) {
        currentLayout = 'bordered-layout';
      } else if ($html.hasClass('semi-dark-layout')) {
        currentLayout = 'semi-dark-layout';
      } else {
        currentLayout = 'light-layout';
      }
      return currentLayout;
    }

    function setLayout(currentLocalStorageLayout) {
      var navLinkStyle = $('.nav-link-style'),
        currentLayout = getCurrentLayout(),
        mainMenu = $('.main-menu'),
        navbar = $('.header-navbar'),
        // Witch to local storage layout if we have else current layout
        switchToLayout = currentLocalStorageLayout
          ? currentLocalStorageLayout
          : currentLayout;

      $html.removeClass('semi-dark-layout dark-layout bordered-layout');

      if (switchToLayout === 'dark-layout') {
        $html.addClass('dark-layout');
        mainMenu.removeClass('menu-light').addClass('menu-dark');
        navbar.removeClass('navbar-light').addClass('navbar-dark');
        navLinkStyle
          .find('.ficon')
          .replaceWith(feather.icons['sun'].toSvg({ class: 'ficon' }));
      } else if (switchToLayout === 'bordered-layout') {
        $html.addClass('bordered-layout');
        mainMenu.removeClass('menu-dark').addClass('menu-light');
        navbar.removeClass('navbar-dark').addClass('navbar-light');
        navLinkStyle
          .find('.ficon')
          .replaceWith(feather.icons['moon'].toSvg({ class: 'ficon' }));
      } else if (switchToLayout === 'semi-dark-layout') {
        $html.addClass('semi-dark-layout');
        mainMenu.removeClass('menu-dark').addClass('menu-light');
        navbar.removeClass('navbar-dark').addClass('navbar-light');
        navLinkStyle
          .find('.ficon')
          .replaceWith(feather.icons['moon'].toSvg({ class: 'ficon' }));
      } else {
        $html.addClass('light-layout');
        mainMenu.removeClass('menu-dark').addClass('menu-light');
        navbar.removeClass('navbar-dark').addClass('navbar-light');
        navLinkStyle
          .find('.ficon')
          .replaceWith(feather.icons['moon'].toSvg({ class: 'ficon' }));
      }
      // Set radio in customizer if we have
      if ($('input:radio[data-layout=' + switchToLayout + ']').length > 0) {
        setTimeout(function () {
          $('input:radio[data-layout=' + switchToLayout + ']').prop(
            'checked',
            true
          );
        });
      }
    }
  };

  const initLayout = (currentLocalStorageLayout) => {
    var $html = $('html');

    var dataLayout = $html.attr('data-layout')
      ? $html.attr('data-layout')
      : 'light-layout';

    var currentLayout =
      currentLocalStorageLayout ??
      localStorage.getItem(dataLayout + '-current-skin', currentLayout);

    var navLinkStyle = $('.nav-link-style'),
      mainMenu = $('.main-menu'),
      navbar = $('.header-navbar'),
      // Witch to local storage layout if we have else current layout
      switchToLayout = currentLayout;

    localStorage.setItem(dataLayout + '-current-skin', switchToLayout);

    $html.removeClass('semi-dark-layout dark-layout bordered-layout');

    if (switchToLayout === 'dark-layout') {
      $html.addClass('dark-layout');
      mainMenu.removeClass('menu-light').addClass('menu-dark');
      navbar.removeClass('navbar-light').addClass('navbar-dark');
      navLinkStyle
        .find('.ficon')
        .replaceWith(feather.icons['sun'].toSvg({ class: 'ficon' }));
    } else if (switchToLayout === 'bordered-layout') {
      $html.addClass('bordered-layout');
      mainMenu.removeClass('menu-dark').addClass('menu-light');
      navbar.removeClass('navbar-dark').addClass('navbar-light');
      navLinkStyle
        .find('.ficon')
        .replaceWith(feather.icons['moon'].toSvg({ class: 'ficon' }));
    } else if (switchToLayout === 'semi-dark-layout') {
      $html.addClass('semi-dark-layout');
      mainMenu.removeClass('menu-dark').addClass('menu-light');
      navbar.removeClass('navbar-dark').addClass('navbar-light');
      navLinkStyle
        .find('.ficon')
        .replaceWith(feather.icons['moon'].toSvg({ class: 'ficon' }));
    } else {
      $html.addClass('light-layout');
      mainMenu.removeClass('menu-dark').addClass('menu-light');
      navbar.removeClass('navbar-dark').addClass('navbar-light');
      navLinkStyle
        .find('.ficon')
        .replaceWith(feather.icons['moon'].toSvg({ class: 'ficon' }));
    }
    // Set radio in customizer if we have
    if ($('input:radio[data-layout=' + switchToLayout + ']').length > 0) {
      setTimeout(function () {
        $('input:radio[data-layout=' + switchToLayout + ']').prop(
          'checked',
          true
        );
      });
    }
  };

  const lang = () => {
    var language = localStorage.getItem('language');
    if (!language) {
      language = $('html')[0].lang;
    }

    if (language !== null) {
      // get the selected flag class
      var selectedLang = $('.dropdown-language')
        .find('a[data-language=' + language + ']')
        .text();
      var selectedFlag = $('.dropdown-language')
        .find('a[data-language=' + language + '] .flag-icon')
        .attr('class');
      // set the class in button
      $('#dropdown-flag .selected-language').text(selectedLang);
      $('#dropdown-flag .flag-icon').removeClass().addClass(selectedFlag);
    }

    $('.dropdown-language .dropdown-item').on('click', function () {
      var $this = $(this);
      $this.siblings('.selected').removeClass('selected');
      $this.addClass('selected');
      var selectedLang = $this.text();
      var selectedFlag = $this.find('.flag-icon').attr('class');
      $('#dropdown-flag .selected-language').text(selectedLang);
      $('#dropdown-flag .flag-icon').removeClass().addClass(selectedFlag);
      // var currentLanguage = $this.data('language');
      // i18next.changeLanguage(currentLanguage, function (err, t) {
      //   $('.main-menu, .horizontal-menu-wrapper').localize();
      // });
    });
  };

  const noti = () => {
    $('.scrollable-container').each(function () {
      var scrollable_container = new PerfectScrollbar($(this)[0], {
        wheelPropagation: false,
      });
    });
  };

  const sidebar = () => {
    var $body = $('body');

    var compactMenu = false;

    if (
      $body.hasClass('menu-collapsed') ||
      localStorage.getItem('menuCollapsed') === 'true'
    ) {
      compactMenu = true;
    }

    $.app.menu.init(compactMenu);
    $.app.nav.init({ speed: 300 });

    Unison.on('change', function (bp) {
      $.app.menu.change(compactMenu);
    });

    $('.navigation').find('li').has('ul').addClass('has-sub');

    modernMenuExpand();

    $('.navbar-header, .main-menu')
      .on('mouseenter', modernMenuExpand)
      .on('mouseleave', modernMenuCollapse);

    function modernMenuExpand() {
      if ($body.data('menu') == 'vertical-menu-modern') {
        $('.main-menu, .navbar-header').addClass('expanded');
        if ($body.hasClass('menu-collapsed')) {
          if ($('.main-menu li.open').length === 0) {
            $('.main-menu-content')
              .find('li.active')
              .parents('li')
              .addClass('open');
          }
          var $listItem = $('.main-menu li.menu-collapsed-open'),
            $subList = $listItem.children('ul');

          $subList.hide().slideDown(200, function () {
            $(this).css('display', '');
          });

          $listItem.addClass('open').removeClass('menu-collapsed-open');
          // $.app.menu.changeLogo('expand');
        }
      }
    }

    function modernMenuCollapse() {
      if (
        $body.hasClass('menu-collapsed') &&
        $body.data('menu') == 'vertical-menu-modern'
      ) {
        setTimeout(function () {
          if (
            $('.main-menu:hover').length === 0 &&
            $('.navbar-header:hover').length === 0
          ) {
            $('.main-menu, .navbar-header').removeClass('expanded');
            if ($body.hasClass('menu-collapsed')) {
              var $listItem = $('.main-menu li.open'),
                $subList = $listItem.children('ul');
              $listItem.addClass('menu-collapsed-open');

              $subList.show().slideUp(200, function () {
                $(this).css('display', '');
              });

              $listItem.removeClass('open');
              // $.app.menu.changeLogo();
            }
          }
        }, 1);
      }
    }
  };

  const resetSidebar = () => {
    var $body = $('body');

    $body.removeClass('menu-collapsed').addClass('menu-expanded');

    if ($body.hasClass('menu-expanded') || $body.hasClass('menu-open')) {
      localStorage.setItem('menuCollapsed', false);
    } else {
      localStorage.setItem('menuCollapsed', true);
    }
  };

  const select2 = () => {
    $('.select2').each(function () {
      var $this = $(this);
      $this.wrap('<div class="position-relative"></div>');
      $this.select2({
        // the following code is used to disable x-scrollbar when click in select input and
        // take 100% width in responsive also
        dropdownAutoWidth: true,
        width: '100%',
        dropdownParent: $this.parent(),
      });
    });
  };

  const contact = () => {
    $('.format-contact').each(function () {
      new Cleave($(this), {
        phone: true,
        phoneRegionCode: 'US',
      });
    });
  };

  const tooltip = () => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  };

  return {
    init,
    auth,
    main,
    mode,
    lang,
    noti,
    sidebar,
    resetSidebar,
    select2,
    contact,
    tooltip,
    initLayout,
  };
})();
